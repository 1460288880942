import React, { useState } from "react"
import { navigate, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { ArrowRight, User, Users } from '@geist-ui/react-icons'

import { StaticImage } from "gatsby-plugin-image";

import queryString from 'query-string'

import Exercise from '../../static/assets/Exercise.png'
import Meal from '../../static/assets/Meal Plan.png'
import Private from '../../static/assets/Private.png'

const About = () => {

const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}
const [featured, setFeatured] = useState(parsed.service || 'private-coaching')


  return <>
    <Helmet>
      <title>Services - Enriched</title>
      <meta name="description" content="Coaching & Consulting" />
    </Helmet>
     <div className="top-bar">
       <a href="#individuals"><User size={16} /><br />For Individuals</a>
       <a href="#businesses"><Users size={16} /><br />For Businesses</a>
     </div>
    <div className="services-page">
      <div className="new-services long">
      <h1>let's talk nutrition</h1>
      </div>
      <div className="new-header">
        <div className="header-text long">
          We're on a mission to <span className="large">provide clarity</span> in a world of confusion, to <span className="large">impart the skills</span> required to filter out the noise, 
          and to <span className="large">be a constant resource</span> within an evolving scientific landscape.
        </div>
        <div className="img-div long">
          <StaticImage
                  src='../../static/assets/Services Header Image.jpg'
                  alt="Our Services"
                />
        </div>
      </div>
      <div className="individuals" id="individuals">
        <h2>For Individuals:</h2>
        <div className="cards new-serv">
          <div className="service">
          <div className="card">
            <div className="front">
              <h4>meal plan</h4>
              <img src={Meal} width="120px" alt="Meal Plan" />
            </div>
            <div className="back">
              <h4>You'll complete:</h4>
                <p>Intake form</p>
                <p>Health history questionnaire</p>
                <p>Wellness habit evaluation</p>
                <br />
              <h4>We'll provide:</h4>
                <p>4-week meal plan</p>
                <p>Recipes & grocery shopping list</p>
                <p>Nutrient information</p>
            </div>
          </div>
          <a href="https://buy.stripe.com/6oE3ff0kU2wWbxC5kk" 
            target="blank" className="livesite-payment">Purchase Plan <ArrowRight size={16} /></a>
          </div>
          <div className="service">
          <div className="card">
            <div className="front">
              <h4>exercise program</h4>
              <img src={Exercise} width="120px" alt="Exercise Program" />
            </div>
            <div className="back">
              <h4>You'll complete:</h4>
                <p>Health history questionnaire</p>
                <p>Wellness habit evaluation</p>
                <p>Virtual movement assessment</p>
                <br />
              <h4>We'll provide:</h4>
                <p>4-week exercise program</p>
                <p>Movement videos</p>
            </div>
          </div>
          <a href="https://buy.stripe.com/9AQeXX0kUgnM9pu9AB" 
            target="blank" className="livesite-payment">Purchase Program <ArrowRight size={16} /></a>
          </div>
          <div className="service">
          <div className="card">
           <div className="front">
              <h4>private coaching</h4>
              <img src={Private} width="120px" alt="Private Coaching" />
            </div>
            <div className="back">
              <h4>You'll complete:</h4>
                <p>Health history questionnaire</p>
                <p>Wellness habit evaluation</p>
                <br />
              <h4>We'll provide:</h4>
                <p>Collaborative Action Plan</p>
                <p>Personalized mobile app with resources</p>
                <p>Unlimited text support</p>
            </div>
          </div>
          <a href="https://buy.stripe.com/eVa7vv2t21sS7hmdQS" 
            target="blank" className="livesite-schedule" 
              data-service="5e99ctc0u1982nb0">Purchase Session <ArrowRight size={16} /></a>
          </div>
        </div>
          <div className="assessment">
            <p>Interested in learning more about your current diet? Track your food for 7 days and receive a comprehensive dietary analysis 
            with practical and evidence-based personalized dietary recommendations.</p>
            <a href="https://buy.stripe.com/eVa8zz1oY8Vk59e003" 
            target="blank"  className="livesite-schedule">Get My Assessment <ArrowRight size={16} /></a>
          </div>
      </div>
      <div className="services-section">
        <div className="img-div">
          <StaticImage
                  src='../../static/assets/Services Mid.jpg'
                  alt="Our Services"
                  imgStyle={{width: '100%', height:'100%', objectFit:'cover'}}
                />
        </div>
        <div className="services-text">
          <p>We're here to help.</p>
          <h3>Not sure where to start?</h3>
          <h6>Schedule a complimentary initial consultation to introduce yourself and discuss your goals.</h6>
          <a href="https://calendly.com/enriched/introductory-call" 
              target="blank" className="livesite-schedule">Book Now</a>
        </div>
      </div>
      <div className="businesses" id="businesses">
      <h2>For Businesses:</h2>
        <div className="cards new-serv">
        <div className="service">
          <div className="card">
           <div className="front">
              <h4>Corporate Wellness</h4>
            </div>
            <div className="back">
              <p>Together we will create the optimal program to meet your group's needs using education, 
              wellness-focused challenges, live Q&A and virtual team workouts.</p>
            </div>
          </div>
          <a href="mailto:nicole@enriched.health?subject=Corporate Wellness Program" 
            target="blank" className="livesite-schedule">Plan Your Program <ArrowRight size={16} /></a>
          </div>
          <div className="service">
          <div className="card">
           <div className="front">
              <h4>Business Consulting</h4>
            </div>
            <div className="back">
              <p>Expert opinions, analyses and recommendations to meet your specific business needs.
              Conference and Keynote Speaking, Nutrition and Exercise Content Development, 
              Scientific Advisory, Media Resource, and more.</p>
            </div>
          </div>
          <a href="mailto:nicole@enriched.health?subject=Business Consulting Inquiry" 
            target="blank" class="livesite-contact">Contact Us <ArrowRight size={16} /></a>
          </div>
        </div>
      </div>
      <div className="services-section">
        <div className="services-text second">
          <h3>Still have questions?</h3>
          <h6>Click below to learn more.</h6>
          <Link to="/detail">Service Details</Link>
        </div>
        <div className="img-div">
          <StaticImage
                  src='../../static/assets/Services Bottom.jpg'
                  alt="Our Services"
                  imgStyle={{width: '100%', height:'100%', objectFit:'cover'}}
                />
        </div>
      </div>
   </div>
  </>
}

export default About
